export class TemplateDetail {
    public Name: string;
    public DepartmentId: string;
    public DepartmentName: string;
    public DownloadLink: string;

    constructor() {
        this.Name = '';
        this.DepartmentId = '';
        this.DepartmentName = '';
        this.DownloadLink = '';
    }
}

import { forkJoin, Observable, Observer } from 'rxjs';
import { TemplateInGrid } from './template-in-grid.model';
import { TemplateDetail } from './template-detail.model';
import { BladeConfig } from 'src/app/core/blade/blade-config';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/http/http.service';


@Injectable({
    providedIn: 'root'
})

export class TemplateService
{
    constructor(
        private http: HttpService
    ) { }


    getReminderOverview(departments: string[], filter: string): Observable<TemplateInGrid[]> {
        return new Observable((observer: Observer<TemplateInGrid[]>) => {
            const httpCalls = [];
            let data: TemplateInGrid[] = [];

            departments.forEach((department) => {
                httpCalls.push(this.http.getReminderApi<TemplateInGrid[]>(department, 'reminders/overview?filter=' + filter));
            });

            forkJoin(httpCalls).subscribe((results: TemplateInGrid[]) => {
                results.forEach((result) => {
                    data = data.concat(result);
                });

                observer.next(data);
                observer.complete();
            });
        });
    }

    getReminderDetail(departmentId: string, templateName: string, culture: string, extension: string): Observable<TemplateDetail> {
        return this.http.getReminderApiWithLanguage(departmentId, `reminders/${templateName}?extension=${extension}`, culture);
    }

    uploadAndReplaceReminder(departmentId: string, templateName: string, culture: string, reminderFile: FormData, extension: string) {
        return this.http.postReminderApiWithLanguage<string>(departmentId, `reminders?templateName=${templateName}&extension=${extension}`, reminderFile, culture);
    }

    getBladeConfigDetail(name: string, data: any): BladeConfig {
        return new BladeConfig(4, name, data);
    }


}

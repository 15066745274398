import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BladeConfig } from "src/app/core/blade/blade-config";
import { BladeRef } from "src/app/core/blade/blade-ref";
import { TranslationService } from "src/app/shared/translation/translation.service";
import { LoaderService } from "src/app/core/loader/loader.service";
import { TemplateDetail } from "src/app/templates/shared/template-detail.model";
import { TemplateInGrid } from "../shared/template-in-grid.model";
import { TemplateService } from "../shared/template.service";
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

@Component({
    selector: "app-templates-detail",
    templateUrl: "./templates-detail.component.html",
    styleUrls: ["./templates-detail.component.scss"],
})
export class TemplatesDetailComponent implements OnInit {
    fileToUpload: File;
    loader = this.loaderService.loading;
    disableSubmitButton = true;
    errorMessage: string;
    successMessage: string;
    template: TemplateDetail = new TemplateDetail();
    previewTemplate = `
            <div class="dz-preview dz-file-preview">
                <div class="dz-details ">
                    <div class="dz-filename"><span data-dz-name style="font-size: 1.3em;"></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
            </div>
        `;

    @ViewChild("fileInput", { static: false })
    public set fileInput(content: ElementRef<HTMLDivElement>) {
        if (content) {
            this.initDropzone(content.nativeElement);
        }
    }

    constructor(
        private config: BladeConfig,
        private bladeRef: BladeRef,
        private service: TemplateService,
        private t: TranslationService,
        private loaderService: LoaderService
    ) {}

    public ngOnInit(): void {
        this.loadTemplateDetail();
    }

    public initDropzone(element: HTMLDivElement): void {
        const myDropzone = new Dropzone(element, {
            url: "/",
            maxFiles: 1,
            createImageThumbnails: false,
            acceptedFiles: ".repx,.rpt",
            previewTemplate: this.previewTemplate,
            autoProcessQueue: false,
        });

        myDropzone.on("addedfile", (file) => this.onFileAdded(file));
    }

    public close(): void {
        this.bladeRef.dismiss();
    }

    public submitFile(): void {
        if (this.disableSubmitButton) return;

        const data: TemplateInGrid = this.config.data;
        const templateName = data.Name.split(".");

        const formData: FormData = new FormData();
        formData.append("fileKey", this.fileToUpload, data.Name);
        const extensionOfUploadedFile = this.fileToUpload.name.substring(
            this.fileToUpload.name.lastIndexOf(".") + 1
        );

        this.service
            .uploadAndReplaceReminder(
                data.DepartmentId,
                templateName[0],
                templateName[1],
                formData,
                extensionOfUploadedFile
            )
            .subscribe(
                () => {
                    this.successMessage = this.t.get("TemplateReplacedSuccess");
                },
                (error) => {
                    this.disableSubmitButton = true;
                    this.fileToUpload = null;

                    if (error.error) {
                        this.errorMessage = error.error;
                    } else {
                        this.errorMessage = this.t.get("GenericErrorLogged");
                    }
                }
            );
    }

    private onFileAdded(file: File): void {
        this.fileToUpload = file;
        this.disableSubmitButton = false;

        this.errorMessage = "";
        this.successMessage = "";
    }

    private loadTemplateDetail(): void {
        const data: TemplateInGrid = this.config.data;

        // Format: DocumentAbonnement_Bewonerskaart_Maxi_Knokke-Heist.nl-BE.rpt
        const templateName = data.Name.split(".");

        this.service
            .getReminderDetail(
                data.DepartmentId,
                templateName[0],
                templateName[1],
                templateName[2]
            )
            .subscribe((t: TemplateDetail) => {
                this.template = t;
                this.loader = this.loaderService.empty;
            });
    }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CookieService } from 'ngx-cookie-service';

import { DxButtonModule, DxDataGridModule, DxDropDownBoxModule, DxListModule, DxTextBoxModule, DxDateBoxModule, DxSelectBoxModule, DxCheckBoxModule } from 'devextreme-angular';
import { NavbarComponent } from './navbar/navbar.component';
import { BreadcrumbsComponent } from './core/breadcrumbs/breadcrumbs.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ComplaintsOverviewComponent } from './complaints/overview/complaints-overview.component';
import { AfterValueChangedDirective } from './shared/after-value-changed/after-value-changed.directive';
import { ManagementSidebarComponent } from './management/sidebar/management-sidebar.component';
import { ComplaintsDetailComponent } from './complaints/detail/complaints-detail.component';
import { ComplaintsApprovalComponent } from './complaints/approval/complaints-approval.component';
import { ComplaintSidebarComponent } from './complaints/sidebar/complaint-sidebar.component';
import { LanguagesOverviewComponent } from './management/languages/overview/languages-overview.component';
import { BladeModule } from './core/blade/blade.module';
import { TokenInterceptor } from './core/auth/token.interceptor';
import { HttpErrorInterceptor } from './core/error/http-error-interceptor';
import { ErrorComponent } from './core/error/error.component';
import { LoaderComponent } from './core/loader/loader.component';
import { AuthService } from './core/auth/auth.service';
import { LightboxModule } from 'ngx-lightbox';
import { LanguagesEditComponent } from './management/languages/edit/languages-edit.component';
import { ValidationComponent } from './core/validation/validation.component';
import { TemplatesOverviewComponent } from './templates/overview/templates-overview.component';
import { TemplatesDetailComponent } from './templates/detail/templates-detail.component';

export function init_auth(service: AuthService) {
    return () => service.init();
}

@NgModule({
    declarations: [
        AppComponent,

        NavbarComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        ComplaintsOverviewComponent,
        AfterValueChangedDirective,
        ManagementSidebarComponent,
        ComplaintsDetailComponent,
        ComplaintsApprovalComponent,
        ComplaintSidebarComponent,
        LanguagesOverviewComponent,
        ErrorComponent,
        LoaderComponent,
        LanguagesEditComponent,
        ValidationComponent,
        TemplatesOverviewComponent,
        TemplatesDetailComponent,
    ],
    imports: [
        BrowserModule, AppRoutingModule, FormsModule, HttpClientModule,

        BladeModule,

        NgbModule, LightboxModule,

        DxButtonModule, DxDataGridModule, DxDropDownBoxModule, DxListModule, DxTextBoxModule, DxDateBoxModule, DxSelectBoxModule, DxCheckBoxModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: init_auth,
            deps: [AuthService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        CookieService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        ManagementSidebarComponent,
        ComplaintSidebarComponent,
        ComplaintsOverviewComponent,
        ComplaintsDetailComponent,
        ComplaintsApprovalComponent,
        LanguagesOverviewComponent,
        LanguagesEditComponent,
        TemplatesOverviewComponent,
        TemplatesDetailComponent
    ]
})

export class AppModule { }
